import React, { Component } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import ru from 'date-fns/locale/ru';

export default class Date extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? moment(props.value.split('.').reverse().join('-')) : null,
            showMonthYearPicker: false,
            showYearPicker: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value != this.props.value && !nextProps.value) {
            this.setState({ value: nextProps.value ? moment(nextProps.value.split('.').reverse().join('-')) : null })
        }
    }

    componentDidMount() {
        this.date.getElementsByTagName('input')[0].readOnly = !this.props.canWrite;
        moment.locale('ru');

    }

    onDate = (date) => {
        let {
            attribute,
            value,
            onChange,
        } = this.props;

        this.setState({ value: date }, () => {
            onChange && onChange(attribute, date ? moment(date).format('DD.MM.YYYY') : null);
        })
    };

    onBlur = (e) => {
        let {
            attribute,
            onBlur,
            saveField,
            value,
        } = this.props;

        if (e && onBlur) {
            onBlur(attribute, e.target.value);
        }

        saveField && saveField(attribute, value);
    }


    handleMonthSelect = (date) => {
        this.setState({
            showMonthYearPicker: false,
            showYearPicker: false,
        });


    };


    toggleMonthYearPicker = () => {
        this.setState(prevState => ({
            showMonthYearPicker: !prevState.showMonthYearPicker
        }));
    };

    toggleYearPicker = () => {
        this.setState({
            showYearPicker: true
        });
    };

    renderCustomHeader = ({
        date,
        decreaseMonth,
        increaseMonth,
        decreaseYear,
        increaseYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => {
        const month = moment(date).format("MMMM")
        const year = Number(moment(date).format("YYYY"))
        const { showMonthYearPicker, showYearPicker } = this.state
        return (
            <div className="datepicker__custom__header">
                <button
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--previous"
                    onClick={showYearPicker || showMonthYearPicker ? decreaseYear : decreaseMonth}
                    disabled={prevMonthButtonDisabled}>
                </button>
                <div className="datepicker__custom__header__title">
                    {!showMonthYearPicker && !showYearPicker &&
                        <span
                            className="datepicker__custom__month"
                            onClick={this.toggleMonthYearPicker}>
                            {showMonthYearPicker ? '' : month}
                        </span>
                    }

                    <span
                        className={showMonthYearPicker || showYearPicker ? 'montn__year__picker__custom' : "datepicker__custom__year"}
                        onClick={this.toggleYearPicker}>
                        {showMonthYearPicker ? year : year}
                    </span>

                </div>
                <button
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--next"
                    onClick={showYearPicker || showMonthYearPicker ? increaseYear : increaseMonth}
                    disabled={nextMonthButtonDisabled}>
                </button>
            </div>
        );
    };

    render() {
        return (
            <div className="daterange__documents">
                <div className={"daterange__input" + (this.state.value ? " filled" : "")}
                    ref={(datepicker) => this.date = datepicker}>
                    <DatePicker
                        selected={this.state.value}
                        onChange={this.onDate}
                        onBlur={this.onBlur}
                        maxDate={moment().toDate()}
                        calendarClassName="datepicker__doc"
                        locale={ru}
                        dateFormat='P'
                        isClearable={true}
                        placeholderText={this.props.placeholder}
                        onCalendarClose={() => this.setState({ showYearPicker: false, showMonthYearPicker: false, })}
                        todayButton="СЕГОДНЯ"
                        onSelect={(date) => this.handleMonthSelect(date)}
                        showMonthYearPicker={this.state.showMonthYearPicker}
                        showYearPicker={this.state.showYearPicker}
                        showFullMonthYearPicker
                        renderCustomHeader={this.renderCustomHeader}
                        shouldCloseOnSelect={false}
                    />
                </div>
            </div>
        )
    }
}