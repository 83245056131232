import React, { Component } from "react";
import PropTypes from "prop-types";
import IconCheck from '../../../../img/icons/check.svg';

function prepareOptions(props) {
    let options = props.options || [];
    if (props.withGroups && props.options) {
        options = [];
        props.options.map((group) => {
            options.push({ label: group.title }, ...group[props.withGroups].sort((a, b) => a.sort > b.sort ? 1 : -1));
        })
    }
    return options;
}

export default class MultiSelect extends Component {
    constructor(props) {
        super(props);
        let selected = [];
        let options = prepareOptions(props);
        // if (props.value) {
        //     for (var i = 0; i < options.length; i++) {
        //         if (options[i].value.indexOf(props.value) !== -1) {
        //             selected.push(options[i]);
        //         }
        //     }
        // }
        this.state = {
            selected,
            opened: false,
            options: options
        }
    }


    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.options != this.props.options) {
            let options = prepareOptions(nextProps)
            this.setState((prevState) => ({ ...prevState, options }));
        }
        // if ((this.state.selected && nextProps.value != this.state.selected.value) || (!this.state.selected && nextProps.value)) {
        //     let selected = [];
        //     if (nextProps.value && nextProps.options) {
        //         let options = prepareOptions(nextProps)
        //         for (var i = 0; i < options.length; i++) {
        //             if (options[i].value.indexOf(nextProps.value) !== -1) {
        //                 selected.push(options[i]);
        //             }
        //         }
        //     }
        //     if (this.filter) {
        //         this.filter.value = selected ? selected.label : ''
        //     }
        //     this.setState((prevState) => ({...prevState, selected}));
        // }
    }

    onClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        var event = document.createEvent("Event");
        event.initEvent("click", false, true);
        document.dispatchEvent(event);

        this.setState((prevState) => {
            return {
                opened: !prevState.opened
            }
        });
    };

    componentDidMount() {
        // document.addEventListener('click', this.windowClick);
        document.getElementById("modal").addEventListener('click', this.windowClick);
        // if (this.filter) {
        //     this.filter.value = this.state.selected ? this.state.selected.label : "";
        //     this.filter.addEventListener('input', this.onFilter);
        // }
    }

    componentWillUnmount() {
        // document.removeEventListener('click', this.windowClick);
        document.getElementById("modal").removeEventListener('click', this.windowClick);
        // if (this.filter) this.filter.removeEventListener('input', this.onFilter);
    }

    windowClick = (e) => {
        if (
            e.target.nodeName !== "LI" &&
            e.target.className !== 'jq-selectbox__trigger' &&
            e.target.className !== 'selectbox__multi_select__box_container' &&
            e.target.className !== 'jq-selectbox__select-text placeholder'
        ) {
            if (this.filter) this.filter.value = this.state.selected ? this.state.selected.label : "";
            this.setState({ opened: false, options: prepareOptions(this.props) });
        }
    };

    onSelect = (item) => {
        return (e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();

            let selected = this.state.selected;
            let findedIndex = -1;
            for (let i = 0; i < selected.length; i++) {
                if (selected[i].value === item.value) {
                    findedIndex = i;
                    break;
                }
            }
            if (findedIndex > -1) {
                selected.splice(findedIndex, 1)
            } else selected.push(item);

            this.setState({
                selected,
            }, () => {
                // if (this.filter) this.filter.value = item ? item.label : "";
                this.props.onChange && this.props.onChange(this.props.attribute, this.state.selected);
                this.props.saveField && this.props.saveField(this.props.attribute, this.state.selected);
                // if (!!this.props.valueSelected) this.props.valueSelected(this.state.selected);
            })

        }
    };

    onFilter = (e) => {
        if (this.props.getOptions) {
            this.props.getOptions(this.props.optionsType, e.target.value);
        }
        var options = [...this.props.options];
        options = options.filter(function (item) {
            return (item.label.toLowerCase()).indexOf(e.target.value.toLowerCase()) !== -1;
        })
        this.setState({ options });
    }

    removeSelectedOption = (e, index) => {
        e.preventDefault()
        e.stopPropagation();

        let selected = this.state.selected;
        selected.splice(index, 1);
        this.setState(
            { selected }, () => {
                this.props.onChange && this.props.onChange(this.props.attribute, this.state.selected);
                this.props.saveField && this.props.saveField(this.props.attribute, this.state.selected);
            }
        );
    }

    onSelectAll = () => {
        return (e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();

            if (this.state.options.length === this.state.selected.length) {
                this.setState({ selected: [] }, () => {
                    this.props.onChange && this.props.onChange(this.props.attribute, this.state.selected);
                    this.props.saveField && this.props.saveField(this.props.attribute, this.state.selected);
                });
            } else {
                this.setState({ selected: [...this.state.options] }, () => {
                    this.props.onChange && this.props.onChange(this.props.attribute, this.state.selected);
                    this.props.saveField && this.props.saveField(this.props.attribute, this.state.selected);
                });
            }

            this.setState(prev => ({
                opened: !prev.opened
            }))
        }
    }

    render() {

        let {
            placeholder = this.context.translate("Выберите договор"),
            allowEmpty = false,
            allowSearch = false
        } = this.props;

        let {
            opened,
            selected,
            options
        } = this.state;

        return <div className="select-styler">
            <div className={"jq-selectbox__select" + (opened ? " opened" : "") + " selectbox__multi_select"} onClick={this.onClick}>
                <div className={"jq-selectbox__select-text" + (selected.length > 0 ? "" : ' placeholder')}>
                    {placeholder}
                </div>

                <div className="jq-selectbox__trigger" key="arrow">
                    <div className="jq-selectbox__trigger-arrow" />
                </div>
            </div>
            {opened ?
                <div className="jq-selectbox__dropdown selectbox__multi_select__options">
                    <ul>
                        {/* {allowEmpty ? <li onClick={this.onSelect(null)} className={selected.length === 0 ? "sel selected placeholder" : "placeholder"}>{placeholder}</li> : null} */}
                        <li key="selectAll" onClick={this.onSelectAll()}
                            className={`${this.state.options.length === this.state.selected.length ? 'selectbox__multi_select_option_selected' : ''}`}
                        >
                            Выбрать все
                            {this.state.options.length === this.state.selected.length && <img src={IconCheck} />}
                        </li>
                        {options && options.map(
                            (item, key) => <li key={key} onClick={item.value ? this.onSelect(item) : () => { }}
                                className={`${selected.find(el => el.value === item.value) ? 'selectbox__multi_select_option_selected' : ''}`}
                            >
                                {item.label}
                                {selected.find(el => el.value === item.value) && <img src={IconCheck} />}
                            </li>)}
                    </ul>

                </div> : null}

            {
                selected.length > 0 ?
                    <div className="selectbox__multi_select__box_container">
                        {
                            selected.map((el, index) => (
                                <div key={index} className={el.label.length > 65 ?"selectbox__multi_select__box_selected__long" : "selectbox__multi_select__box_selected"}>
                                    <span className="selectbox__multi_select__box_selected__text">{el.label}</span>
                                    <span
                                        className="selectbox__multi_select__box_selected__remove"
                                        onClick={(e) => { this.removeSelectedOption(e, index) }}
                                    >
                                        <svg width="12" height="12" viewBox="0 0 12 12"><path id="path0_fill" data-name="path0 fill" d="M10.4,0,6,3.83,1.6,0,0,1.6,3.83,6,0,10.4,1.6,12,6,8.18,10.4,12,12,10.4,8.18,6,12,1.6Z" /></svg>
                                    </span>
                                </div>
                            ))
                        }
                    </div> : ''
            }
        </div>
    }
}