import React, { Component } from "react";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import DocumentLoader from "../common/DocumentLoader";
import FileIcon from "../common/FileIcon";
import { getFileSize } from "../../tools/tools";
import SuccessMessage from "./SuccessMessage";
import Modal from "../common/Modal";
import PropTypes from "prop-types";

export default class NewMessageForm extends Component {

    constructor(props) {
        super(props);

        var type = "";
        if (props.fromHelp && props.catalog.communicationTypes) {
            var n = props.catalog.communicationTypes.length;
            for (var i = 0; i < n; i++) {
                if (props.catalog.communicationTypes[i].label === "Консультация") {
                    type = props.catalog.communicationTypes[i].value;
                    props.createDraft(type);
                    break;
                }
            }
        }

        var fields = props.isFirst ? {
            CommunicationTypeId: {
                type: 'select',
                label: "Тип запроса",
                placeholder: "Выберите тип",
                value: type,
                options: props.catalog && props.catalog.communicationTypes || [],
                optionsType: 'communicationTypes',
                required: true
            },
            Message: {
                type: 'textarea',
                label: "Текст обращения",
                placeholder: "Введите текст",
                value: "",
                required: true
            }
        } : {
            Message: {
                type: 'textarea',
                label: "Комментарий",
                placeholder: "Введите текст",
                value: "",
                required: true
            }
        };

        this.state = {
            fields,
            modal: false,
            files: [],
            // modalSuccessMessage: false,
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentDidMount() {
        if (this.props.location.state === 'upload') {
            this.toggleModal();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.fields.CommunicationTypeId && nextProps.catalog != this.props.catalog) {
            var fields = { ...this.state.fields };
            fields.CommunicationTypeId.options = nextProps.catalog.communicationTypes || [];
            this.setState({ fields });
        }

        if (this.props.files != nextProps.files) {

            this.setState((prevState) => {
                return {
                    files: nextProps.files,
                    fields: {
                        ...prevState.fields,
                        Message: {
                            ...prevState.fields.Message,
                            style: { paddingBottom: 45 * (nextProps.files.length + 1) + "px" }
                        }
                    }
                }
            });
        }

        if (!nextProps.isFirst && this.props.detail != nextProps.detail) {
            var fields = { ...this.state.fields };
            fields.Message.value = "";
            this.setState({ fields, files: [], isSending: false });
        }
    }

    renderField = (attribute) => {
        var fields = { ...this.state.fields };

        return fields.hasOwnProperty(attribute) && !fields[attribute].hidden ?
            <FormField fieldConfig={{
                ...fields[attribute],
                label: this.context.translate(fields[attribute].label),
                placeholder: this.context.translate(fields[attribute].placeholder)
            }} attribute={attribute} onChange={this.onChange} /> : null;
    }

    onChange = (attribute, value, error) => {
        var fields = { ...this.state.fields };
        fields[attribute].value = value;
        fields[attribute].error = error;
        this.setState({ fields });

        if (attribute == 'CommunicationTypeId') {
            this.props.createDraft(value);
        }
    }

    // открывает содалку "Успешное сообщение"
    openModalSuccessMessage = () => {
        this.setState({ modalSuccessMessage: true });
    };

    // закрывает модалку "Успешное сообщение"
    closeModalSuccessMessage = () => {
        this.setState({ modalSuccessMessage: false });

        // отправляем форму
        if (this.props.detail.CommunicationId && this.state.fields.Message.value) {
            this.props.send(this.props.detail.CommunicationId, this.state.fields.Message.value);
            this.setState({ isSending: true });
        }
    };

    // открываем модальное окно
    onSubmit = () => {
        if (this.props.isFirst) return this.openModalSuccessMessage();
        this.closeModalSuccessMessage()
        
    }

    toggleModal = (e) => {
        if (e) e.preventDefault();
        if (this.props.detail.CommunicationId) {
            this.props.clearBuffer();
            this.setState((prevState) => {
                return { modal: !prevState.modal }
            })
        }
    }

    addDocument = (file) => {
        this.props.addDocument(this.props.detail.CommunicationId, file);
    }

    removeDocument = (id) => {
        this.props.removeDocument(this.props.detail.CommunicationId, id);
    }

    cancelDocument = (id) => {
        var files = [...this.state.files];
        files = files.filter((item) => { return item.ID != id });
        this.setState({ files })
    }

    updateDocument = (id, name) => {
        this.props.updateDocument(this.props.detail.CommunicationId, id, name);
    }

    renderFiles = () => {
        var attaches = [];
        var files = [...this.state.files];

        files.map((file) => {
            attaches.push(
                <div className="attach attach_adapt-default" key={file.ID}>
                    <div className="attach__type"><FileIcon ext={file.name.substr(file.name.lastIndexOf('.') + 1)} /></div>
                    <div className="attach__content">
                        <div className="attach__name">{file.filename || file.name}</div>
                        <div className="attach__size">{getFileSize(file.size)}</div>
                    </div>
                    <button className="attach__remove" onClick={() => { this.removeDocument(file.ID) }} />
                </div>);
        });

        return attaches;
    }

    isValid = () => {
        if (this.state.isSending) return false;

        var fields = { ...this.state.fields };
        for (var attr in fields) {
            if (fields[attr].required && !fields[attr].value || fields[attr].error) {
                return false;
            }
        }
        return true;
    }

    render() {
        const { modalSuccessMessage } = this.state;

        var {
            fileBuffer = [],
            fileParams,
            detail,
        } = this.props;



        return (
            <React.Fragment>
                <Form className="page__request-add-form" onSubmit={this.onSubmit}>
                    <div className="page__request-add-type">
                        {this.renderField('CommunicationTypeId')}
                    </div>
                    <div className="page__request-add-text">
                        <div className="request-form request-form_adapt-default">
                            <div className="request-form__text">
                                {this.renderField('Message')}
                                <div className="request-form__attaches">
                                    {this.renderFiles()}
                                </div>
                            </div><a className={"request-form__attach" + (detail.CommunicationId ? "" : " disabled")} href="#" onClick={this.toggleModal}>{this.context.translate('Добавить файлы')}</a>
                            <button className={"u-btn request-form__submit" + (this.isValid() ? "" : " disabled")} type="submit">{this.context.translate('Отправить')}</button>
                        </div>
                    </div>

                    {this.state.modal
                        && (
                            <DocumentLoader
                                files={fileBuffer}
                                {...fileParams}
                                addDocument={this.addDocument}
                                removeDocument={this.removeDocument}
                                updateDocument={this.updateDocument}
                                cancelDocument={this.cancelDocument}
                                close={this.toggleModal}
                            />
                        )}
                </Form>

                {modalSuccessMessage
                    && (
                        <Modal modalSize="s"
                            classNameContent="modal modal_visible"
                            onClickBackground={this.closeModalSuccessMessage}
                        >
                            <SuccessMessage
                                communicationId={this.props.detail.CommunicationId}
                                close={this.closeModalSuccessMessage}
                            />
                        </Modal>
                    )}
            </React.Fragment>
        )
    }
}