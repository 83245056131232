import React, { Component } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import ru from 'date-fns/locale/ru';
import { isValidDateFormat, parseDate } from "../../../tools/tools";

export default class DateRange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            from: props.value && props.value.from ? moment(props.value.from.split('.').reverse().join('-'), 'DD.MM.YYYY') : null,
            to: props.value && props.value.to ? moment(props.value.to.split('.').reverse().join('-'), 'DD.MM.YYYY') : null,
            showMonthYearPicker: false,
            showYearPicker: false,
        }
        this.datePickerRef = React.createRef();
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.value != this.props.value && !nextProps.value) {
            this.setState({
                from: null,
                to: null
            })
        }
    }

    componentDidMount() {
        this.dateFrom.getElementsByTagName('input')[0].readOnly = !this.props.canWrite;
        this.dateTo.getElementsByTagName('input')[0].readOnly = !this.props.canWrite;
        moment.locale('ru');
    }
    onDateFrom = (date, event) => {
        let {
            attribute,
            value,
            onChange
        } = this.props;

        if (event.target.value) {
            if (isValidDateFormat(event.target.value)) {
                let val = event.target.value;
                this.setState({ from: parseDate(val) }, () => {
                    onChange && onChange(attribute, {
                        from: parseDate(val) ? moment(parseDate(val)).format('DD.MM.YYYY') : null,
                        to: value.to
                    })
                })
            }
        } else {
            this.setState({ from: date }, () => {
                onChange && onChange(attribute, {
                    from: date ? moment(date).format('DD.MM.YYYY') : null,
                    to: value.to
                })
            })
        }
    };

    onDateTo = (date, event) => {
        let {
            attribute,
            value,
            onChange
        } = this.props;

        if (event.target.value) {
            if (isValidDateFormat(event.target.value)) {
                let val = event.target.value;
                this.setState({ to: parseDate(val) }, () => {
                    onChange && onChange(attribute, {
                        from: value.from,
                        to: parseDate(val) ? moment(parseDate(val)).format('DD.MM.YYYY') : null,
                    })
                })
            }
        } else {
            this.setState({ to: date }, () => {
                onChange && onChange(attribute, {
                    from: value.from,
                    to: date ? moment(date).format('DD.MM.YYYY') : null,
                })
            })
        }
    };

    onDateRange = (range) => {
        return () => {
            let {
                attribute,
                onChange
            } = this.props;

            var newFrom = moment().subtract(1, range).toDate(),
                newTo = moment().toDate();
            this.setState({ from: newFrom, to: newTo }, () => {
                onChange && onChange(attribute, {
                    from: moment(newFrom).format('DD.MM.YYYY'),
                    to: moment(newTo).format('DD.MM.YYYY')
                })
            })

        }
    };
    handleMonthSelect = (date) => {
        this.setState({
            showMonthYearPicker: false,
            showYearPicker: false,
        });


    };


    toggleMonthYearPicker = () => {
        this.setState(prevState => ({
            showMonthYearPicker: !prevState.showMonthYearPicker
        }));
    };

    toggleYearPicker = () => {
        this.setState({
            showYearPicker: true
        });
    };
    renderCustomHeader = ({
        date,
        decreaseMonth,
        increaseMonth,
        decreaseYear,
        increaseYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => {
        const month = moment(date).format("MMMM")
        const year = Number(moment(date).format("YYYY"))
        const { showMonthYearPicker, showYearPicker } = this.state
        return (
            <div className="datepicker__custom__header">
                <button
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--previous"
                    onClick={showYearPicker || showMonthYearPicker ? decreaseYear : decreaseMonth}
                    disabled={prevMonthButtonDisabled}>
                </button>
                <div className="datepicker__custom__header__title">
                    {!showMonthYearPicker && !showYearPicker &&
                        <span
                            className="datepicker__custom__month"
                            onClick={this.toggleMonthYearPicker}>
                            {showMonthYearPicker ? '' : month}
                        </span>
                    }

                    <span
                        className={showMonthYearPicker || showYearPicker ? 'montn__year__picker__custom' : "datepicker__custom__year"}
                        onClick={this.toggleYearPicker}>
                        {showMonthYearPicker ? year : year}
                    </span>

                </div>
                <button
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--next"
                    onClick={showYearPicker || showMonthYearPicker ? increaseYear : increaseMonth}
                    disabled={nextMonthButtonDisabled}>
                </button>
            </div>
        );
    };

    render() {

        return <div className="daterange">
            <div className="daterange__row daterange__row_flex">
                <div className={"daterange__input" + (this.state.from ? " filled" : "")}
                    ref={(datepicker) => this.dateFrom = datepicker}
                    key="date-from"
                >
                    <DatePicker
                        ref={this.datePickerRef}
                        selected={this.state.from}
                        onChange={(date, event) => this.onDateFrom(date, event)}
                        maxDate={this.state.to || moment().toDate()}
                        calendarClassName="datepicker__custom"
                        isClearable={true}
                        locale={ru}
                        dateFormat='P'
                        onCalendarClose={() => this.setState({ showYearPicker: false, showMonthYearPicker: false })}
                        todayButton="СЕГОДНЯ"
                        // onSelect={(date) => this.onDateFrom(date)}
                        // Включаем showMonthYearPicker на основе состояния
                        onSelect={(date) => this.handleMonthSelect(date)}
                        showMonthYearPicker={this.state.showMonthYearPicker}
                        showYearPicker={this.state.showYearPicker}
                        showFullMonthYearPicker
                        renderCustomHeader={this.renderCustomHeader}
                        shouldCloseOnSelect={false}

                    />
                </div>
                <div className="daterange__separate" />
                <div className={"daterange__input" + (this.state.to ? " filled" : "")}
                    ref={(datepicker) => this.dateTo = datepicker}
                    key="date-to"
                >
                    <DatePicker
                        selected={this.state.to}
                        onChange={(date, event) => this.onDateTo(date, event)}
                        minDate={this.state.from}
                        maxDate={moment().toDate()}
                        calendarClassName="datepicker__custom__to"
                        isClearable={true}
                        locale={ru}
                        dateFormat='P'
                        onCalendarClose={() => this.setState({ showYearPicker: false, showMonthYearPicker: false, })}
                        todayButton="СЕГОДНЯ"
                        onSelect={(date) => this.handleMonthSelect(date)}
                        showMonthYearPicker={this.state.showMonthYearPicker}
                        showYearPicker={this.state.showYearPicker}
                        showFullMonthYearPicker
                        renderCustomHeader={this.renderCustomHeader}
                        shouldCloseOnSelect={false}

                    />
                </div>
            </div>

            {this.props.showPeriods ? <div className="daterange__row">
                <span className="daterange__period" onClick={this.onDateRange('week')}>{this.context.translate("За неделю")}</span>
                <span className="daterange__period" onClick={this.onDateRange('months')}>{this.context.translate("За месяц")}</span>
                <span className="daterange__period" onClick={this.onDateRange('years')}>{this.context.translate("За год")}</span>
            </div> : null}
        </div>
    }
}
